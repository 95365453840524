import Link from "next/link";
import React, { SyntheticEvent } from "react";
import { BlurImage } from "@components/BlurImage";
import { MobileNav } from "./MobileNav";
import { DesktopNav } from "./DesktopNav";
import { SignInButton, UserButton, useUser } from "@clerk/nextjs";
import { AccountLink } from "@components/AccountLink";

const navItems = [
  {
    id: -1,
    name: "Tools & Services",
    link: "#",
    onClick: (e: SyntheticEvent) => e.preventDefault(),
    children: [
      {
        id: 1,
        name: "Solutions Marketplace",
        link: "/solutions-marketplace",
      },
      {
        id: 2,
        name: "AI Literacy",
        link: "/ai-literacy",
      },
      {
        id: 3,
        name: "Desert Sentry CSO",
        link: "/cdao-vehicles/computer-vision-defense-commercial-solutions-opening-cso",
      },
      {
        id: 4,
        name: "AI Acquisition Playground",
        link: "/ai-acquisition-playground",
      },
    ],
  },
  {
    id: -2,
    name: "Contract Vehicles",
    link: "/cdao-vehicles",
    children: [
      {
        id: 1,
        name: "T & E",
        link: "/cdao-vehicles/test-and-evaluation-services-blanket-purchase-agreement-bpa",
      },
      {
        id: 2,
        name: "DRAID",
        link: "/cdao-vehicles/data-readiness-for-artificial-intelligence-development-draid-boa",
      },
      {
        id: 3,
        name: "AI Talent",
        link: "/cdao-vehicles/ai-talent",
      },
    ],
  },
  {
    id: 0,
    name: "Opportunities",
    link: "/opportunities",
  },
  {
    id: 2,
    name: "Resources",
    link: "/education-center",
    children: [
      {
        link: "/education-center",
        name: "Education Center",
      },
      {
        link: "/events",
        name: "Events",
      },
      {
        link: "https://defensemavericks.com",
        name: "Podcast",
      },
      {
        link: "/responsible-ai",
        name: "Responsible AI",
      },
    ],
  },
  {
    id: 4,
    name: "About us",
    link: "/about",
    children: [
      {
        link: "/about",
        name: "About Tradewinds",
      },
      {
        link: "/contact",
        name: "Contact",
      },
    ],
  },
  {
    id: 5,
    name: "Awardees",
    link: "https://tradewindsai.com/awardees",
  },
];

export const Navbar = () => {
  const user = useUser();

  return (
    <div className="flex flex-row items-center justify-between mx-auto py-4 relative z-50 w-full md:px-20">
      <Link
        href="/"
        className="relative w-[10rem] h-[30px] md:w-[14rem] md:h-[40px]"
      >
        <BlurImage
          fill
          src={`/images/logo-white.svg`}
          className="object-contain pl-6 md:pl-0"
        />
      </Link>

      <div className="xl:flex flex-row flex-1 hidden items-center">
        <div className="mx-auto flex items-center">
          <DesktopNav navItems={navItems} />
        </div>
        {!user?.user ? (
          <SignInButton afterSignInUrl="/" afterSignUpUrl="/" mode={`redirect`}>
            <button className="hidden xl:block relative text-sm font-bold px-6 py-2 bg-themecolor text-black transition duration-200 rounded-full mx-2">
              Login
            </button>
          </SignInButton>
        ) : (
          <>
            <AccountLink type="desktop" />
            <UserButton afterSignOutUrl="/" />
          </>
        )}
      </div>

      <div className="flex xl:hidden py-6 pr-6">
        <MobileNav navItems={navItems} user={user} />
      </div>
    </div>
  );
};
